<template>
    <div class="maintenance-type">
        <title-bar
            submitBtn
            :submitBtnTitle="$t('messages.maintenanceTypeCreate')"
            :title="$t('messages.maintenanceTypeCreate')"
            @submitPressed="submitPressed"
        />
        <resource-form :resource-object="entityOption" :resource="$EntityOptions" base-url="/settings/maintenance-types" create-message="messages.maintenanceTypeCreated" update-message="messages.maintenanceTypeUpdated" :action="action" @clearAction="action = null"/>
    </div>
</template>

<script>
import TitleBar from '@/components/TitleBar.vue'
import ResourceForm from '../components/ResourceForm.vue'

export default {
    components: {
        TitleBar,
        ResourceForm
    },
    data() {
        return {
            action: null,
            entityOption: {
                type: 'MAINTENANCE_TYPE'
            }
        }
    },
    methods: {
        submitPressed() {
            this.action = 'add'
        }
    }
}
</script>
